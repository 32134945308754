import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: black;
`;

export const Box = styled.div`
    width: 400px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .texto{
        color: white;
        font-weight: 500;
        font-size: 16px;
    }

    input{
        margin-top: 5px;
        margin-bottom: 20px;
        width: 100%;
        height: 35px;
        border-radius: 3px;
        border: 0;
        padding-left: 20px;
        box-sizing: border-box;
    }

    button{
        width: 100%;
        height: 40px;
        border-radius: 3px;
        background: linear-gradient(to right, rgba(0, 255, 0, 1), rgba(100, 255, 0, 1));
        color: black;
        font-size: 14px;
        cursor: pointer;
        box-sizing: border-box;

        transition: .3s;
        &:hover{
            color: white;
        }
    }

`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

