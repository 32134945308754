import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useLoad } from './LoadContext';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [clientExtracts, setClientExtracts] = useState(null);
    const GET_CLIENT_DATA_ROUTE = process.env.REACT_APP_BASE_ROUTE + process.env.REACT_APP_CLIENT_DATAILS;
    const GET_CLIENT_EXTRACTS_ROUTE = process.env.REACT_APP_BASE_ROUTE + "extract/client/";
    const GET_CLIENT_NOTICIAS = process.env.REACT_APP_BASE_ROUTE + "news";
    const [isInitialized, setIsInitialized] = useState(false);
    const [noticias, setNoticias] = useState(null);
    const [modeloDeContratos, setModeloDeContratos] = useState([]);
    const [monthsToWithdraw, setMonthsToWithdraw] = useState(null);
    const [daysToWithdraw, setDaysToWithdraw] = useState(null);
    const [dollar, setDollar] = useState(6);
    const [indicationPercent, setIndicationPercent] = useState(null);
    const [indications, setIndications] = useState(null);
    const { startLoading, stopLoading, stopLoadingDelay } = useLoad();

    useEffect(() => {
        const storedToken = sessionStorage.getItem('authToken');
        const storedId = sessionStorage.getItem('userId');

        if (storedToken && storedId) {
            setToken(storedToken);
            obterNoticias();
            obterConfiguracoesDoSistema(token);
            obterDollarHoje();
            fetchClientData(storedToken, storedId)
                .then(() => { obterModeloDeContratos(storedToken); })
                .finally(() => setIsInitialized(true));
        } else {
            setIsInitialized(true);
        }
    }, []);

    const fetchClientData = async (token, id) => {
        startLoading();
        try {
            const response = await axios.get(`${GET_CLIENT_DATA_ROUTE}${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            await obterExtratos(token, id)
            const Client = response.data.client;

            var totalInvestimento = Client.blockedBalance;
            var lucroTotalObtido = 0;
            var lucroAReceber = 0;

            Client.walletExtract.purchases.forEach(purchase => {
                if (purchase.status === 2) {
                    lucroTotalObtido += parseFloat(purchase.currentIncome);
                    lucroAReceber += parseFloat(purchase.finalIncome) - parseFloat(purchase.currentIncome);
                }
            });

            var totalJaSacado = 0;

            Client.walletExtract.withdrawals.forEach(withdrawal => {
                if (withdrawal.status === 1 || withdrawal.status === 2) {
                    totalJaSacado += withdrawal.amountWithdrawn;
                }
            });

            const returnClient = {
                ...response.data.client,
                totalInvestimento,
                lucroTotalObtido,
                lucroAReceber,
                totalJaSacado
            };
            stopLoadingDelay();
            setClientData(returnClient);
        } catch (error) {
            stopLoadingDelay();
            console.error("Erro ao buscar dados do cliente:", error);
        }
    };

    const obterNoticias = () => {
        startLoading()
        axios.get(`${GET_CLIENT_NOTICIAS}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
            if (res.data) {
                stopLoading()
                setNoticias(res.data)
                return res.data;
            }
        }).catch(err => {
            stopLoading()
            console.log(err)
            return null;
        })
    }


    const obterExtratos = async (token, id) => {
        startLoading()
        axios.get(`${GET_CLIENT_EXTRACTS_ROUTE}${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
            if (res.data) {
                stopLoading()
                setClientExtracts(res.data)
                return res.data;
            }
        }).catch(err => {
            stopLoading()
            return null;
            console.log(err)
        })
    }

    const obterModeloDeContratos = async (token) => {
        startLoading();
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}contract`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data) {
                setModeloDeContratos(response.data);
            }
        } catch (error) {
            console.error("Erro ao obter modelos de contratos:", error);
        } finally {
            stopLoading();
        }
    }

    const obterDollarHoje = async (token) => {
        startLoading();
        try {
            const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`);

            if (response.data) {
                setDollar(response.data.rates.BRL);
                console.log(response.data.rates.BRL)
            }
        } catch (error) {
            console.error("Erro ao obter modelos de contratos:", error);
        } finally {
            stopLoading();
        }
    }

    const obterIndicacoes = async (token, clientId) => {
        startLoading();
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}indication/client/${clientId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });


            if (response.data) {
                setIndications(response.data);
            }

        } catch (error) {
            console.error("Erro ao obter indicações:", error);
        } finally {
            stopLoading();
        }
    }

    const obterConfiguracoesDoSistema = async (token) => {
        startLoading();
        try {
            const response1 = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}systemconfig/withdrawn_date_months`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const response2 = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}systemconfig/withdrawn_date_days`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const response3 = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}systemconfig/indication`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (await response1.data) {
                setMonthsToWithdraw(response1.data.value);
            }

            if (await response2.data) {
                setDaysToWithdraw(response2.data.value);
            }

            if (await response3.data) {
                setIndicationPercent(response3.data.value);
            }
        } catch (error) {
            console.error("Erro ao obter modelos de contratos:", error);
        } finally {
            stopLoading();
        }
    }

    const refreshClientData = async () => {
        if (token) {
            const storedId = sessionStorage.getItem('userId');
            if (storedId) {
                await fetchClientData(token, storedId);
                await obterModeloDeContratos(token);
                await obterNoticias();
                await obterConfiguracoesDoSistema();
                await obterDollarHoje();
            }
        }
    };

    useEffect(() => {
        if (clientData && clientData.id)
            obterIndicacoes(token, clientData.id);
    }, [clientData])

    const login = async (cpf, password) => {
        startLoading();
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_ROUTE + process.env.REACT_APP_AUTH_TOKEN, {
                id: cpf,
                password
            });
            const receivedToken = response.data.token;
            setToken(receivedToken);
            sessionStorage.setItem('authToken', receivedToken);
            sessionStorage.setItem('userId', cpf);
            await fetchClientData(receivedToken, cpf);
            await obterModeloDeContratos(receivedToken);
            await obterNoticias();
            setTimeout(stopLoading, 1200);
            return true;
        } catch (error) {
            console.error("Erro ao logar:", error);
            setToken(null);
            setTimeout(stopLoading, 1200);
            return false;
        }
    };

    const logout = () => {
        startLoading();
        setToken(null);
        setClientData(null);
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userId');
        setTimeout(stopLoading, 500);
    };

    return (
        <AuthContext.Provider value={{
            token, clientData, clientExtracts, login, logout, isInitialized, refreshClientData, modeloDeContratos, noticias, obterNoticias,
            daysToWithdraw, monthsToWithdraw, indicationPercent, indications, dollar
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;