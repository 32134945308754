import React, { useState, useEffect, useContext } from "react";
import * as S from "./ModalDeInvestirStyle";
import { helpers } from "../../../Helpers/helpers";
import { AuthContext } from "../../../context/AuthContext";
import { useLoad } from "../../../context/LoadContext";

export default function ModalDeInvestir({ onClose }) {
    const { clientData, refreshClientData, token } = useContext(AuthContext);
    const [valorDesejado, setValorDesejado] = useState("");
    const [corDoInput, setCorDoInput] = useState("white");
    const { startLoading, stopLoading, stopLoadingDelay } = useLoad();
    const [selecteds, setSelecteds] = useState(null);
    const [amountAv, setAmountAv] = useState(0);


    const handleClick = (item) => {
        if (selecteds === item) {
            setSelecteds(null)
            setAmountAv(0);
        } else {
            setSelecteds(item);
            var compra = clientData.walletExtract.purchases.find(c => c.purchaseId === item);
            setAmountAv(parseFloat(compra.currentIncome - compra.amountWithdrawn));
        }
    }


    useEffect(() => {
        if (valorDesejado && valorDesejado.trim() !== "") {
            if (valorDesejado <= 0) {
                setCorDoInput("red");
            } else if (parseFloat(valorDesejado) <= parseFloat(amountAv)) {
                setCorDoInput("green");
            } else {
                setCorDoInput("red");
            }
        } else {
            setCorDoInput("white");
        }
    }, [valorDesejado, clientData.amountAvailableToWithdraw]);

    const handleWithdraw = async () => {

        if ((parseFloat(valorDesejado) > parseFloat(amountAv)) || valorDesejado <= 0)
            return;

        if (!selecteds){
            alert("Selecione o Contrato Para Reinvestir o Lucro");
            return;
        }

        startLoading();

        const res = await helpers.adicionarSaldoAContrato(selecteds, valorDesejado, onClose, refreshClientData, token);
        if (res) {
            alert("Valor Reinvestido Com Sucesso! 👍🏻");
        } else {
            alert("Erro ao reinvestir lucro! 👎🏻");
        }
        stopLoadingDelay();
    }

    return (
        <S.ModalContainer>
            <S.ModalContentBox>
                <S.BackButton>
                    <img onClick={onClose} alt="voltar" src="./back-arrow.png" />
                </S.BackButton>

                <S.ModalTitle>reinvista seus lucros</S.ModalTitle>


                <S.SelecioneOContrato>
                    {clientData.walletExtract.purchases && clientData.walletExtract.purchases.filter(c => c.status === 2).map(c => (
                        <>
                            <div onClick={() => handleClick(c.purchaseId)} className={`contract ${selecteds === c.purchaseId ? "selected" : "normal"}`} key={c.purchaseId}>
                                {c.purchaseId}
                            </div>
                        </>
                    ))}
                </S.SelecioneOContrato>

                <S.DisponivelSaque>
                    <p>Disponível Para Investir: </p>
                    <span
                        onClick={() => setValorDesejado(clientData.amountAvailableToWithdraw.toFixed(2))}
                        style={{ color: clientData.amountAvailableToWithdraw > 0 ? "rgba(100, 200, 0, 1)" : "rgba(0, 50, 200, 1)" }}
                    >
                        R${helpers.formatToBrazilianCurrency(amountAv)}
                    </span>
                </S.DisponivelSaque>

                <S.SelecioneValor>
                    <p>Digite a quantidade desejada</p>
                    <div className="inputBox">
                        <input
                            style={{ color: corDoInput }}
                            value={valorDesejado}
                            onChange={(e) => setValorDesejado(e.target.value)}
                            type="number"
                        />
                        <button onClick={() => setValorDesejado(clientData.amountAvailableToWithdraw.toFixed(2))}>TOTAL</button>
                    </div>
                </S.SelecioneValor>

                <S.BotaoSaque onClick={handleWithdraw}>Realizar Reinvestimento</S.BotaoSaque>
            </S.ModalContentBox>
        </S.ModalContainer>
    );
}
