import styled from "styled-components";

export const IndicationContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100vh;
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 36px;
    color: white;
    text-shadow: 3px 3px 1px rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img{
        width: 60px;
    }

    @media(max-width: 1100px){
        font-size: 26px;

        img{
            width: 40px;
        }   
    }
`;

export const Propaganda = styled.div`
    width: 100%;
    height: 350px;
    background: radial-gradient(rgba(53, 56, 79, 1), rgba(39, 42, 59, 1));
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    .mensagem{
        margin: 0;
        font-size: 34px;
        color: white;
        font-weight: 500;

        .bold{
            color: #fcf300;
            font-weight: 800;
        }
    }

    .yourLink{
        margin: 0;
        font-size: 16px;
        color: white;
        font-weight: 500;

        .bold{
            color: rgba(0, 180, 255, 1);
            font-weight: 800;
            cursor: pointer;
            transition: .3s;

            &:hover{
                color: #fcf300;
            }
        }
    }

    @media(max-width: 1100px){
        .mensagem{
            font-size: 18px;
            text-align: center;
        }

        .yourLink{
            text-align: center;
            font-size: 14px;

            .bold{
                font-size: 14px;
            }
        }
    }
`;

export const IndicacaoTableContainer = styled.div`
    width: 100%;
    overflow: auto;
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
