import React, { useContext, useEffect, useState } from "react";
import * as S from "./ProfileStyle"
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { helpers } from "../../Helpers/helpers";

export default function Profile({ handleClose }) {
    const { clientData } = useContext(AuthContext);
    const [editContact, setEditContact] = useState(false);
    const [editStreet, setEditStreet] = useState(false);
    const [editStreetNumber, setEditStreetNumber] = useState(false);
    const [editNeighborhood, setEditNeighborhood] = useState(false);
    const [editCity, setEditCity] = useState(false);
    const [editState, setEditState] = useState(false);
    const [editCountry, setEditCountry] = useState(false);
    const [editZipcode, setEditZipcode] = useState(false);

    const [contact, setContact] = useState("");
    const [street, setStreet] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipCode] = useState("");

    useEffect(() => {
        if (clientData) {
            setContact(clientData.phone);
            setStreet(clientData.address.street);
            setStreetNumber(clientData.address.number);
            setNeighborhood(clientData.address.neighborhood);
            setCity(clientData.address.city);
            setState(clientData.address.state);
            setCountry(clientData.address.country || "");
            setZipCode(clientData.address.zipcode);
        }
    }, [clientData]);

    const handleEditContact = () => {
        if(contact.trim() != ""){
            console.log(contact)
            helpers.editarContatoCliente(clientData.id, contact);
            setEditContact(false);
        }else{
            alert("Preencha o campo para salvar");
            return;
        }
    }

    const handleEditAddress = () => {
        if(contact.trim() != ""){
            console.log(contact)
            helpers.editarEnderecoCliente(clientData.id, {
                street: street,
                number: streetNumber,
                zipcode: zipcode,
                city: city,
                neighborhood: neighborhood,
                state: state,
                country: country,
            });
            setEditCity(false);
            setEditContact(false);
            setEditCountry(false);
            setEditNeighborhood(false);
            setEditState(false);
            setEditStreet(false);
            setEditStreetNumber(false);
            setEditZipcode(false);
        }else{
            alert("Preencha o campo para salvar");
            return;
        }
    }

    return (
        <>
            <S.ProfileContainer>
                <S.ProfileContent>
                    <S.ProfilePictureArea>
                        <h5 onClick={handleClose}>x</h5>
                        <div className="ProfilePictureBox">
                            {/* <img alt="Profile Picture" src={"developer-image.jpeg"} /> */}
                            <img alt="Profile Picture" src={"profile-icon.png"} />
                        </div>
                        {/* <span>Alterar</span> */}
                    </S.ProfilePictureArea>

                    <S.ProfileInfo>
                        <h1>Informações Pessoais</h1>

                        <S.InfoBox>
                            <span>Nome</span>
                            <input placeholder={`Seu Nome Completo`} value={clientData.name} />
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>CPF</span>
                            <input readOnly placeholder="000.000.000-00" value={clientData.id} />
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>Contato</span>
                            <input readOnly={!editContact} onChange={(e) => setContact(e.target.value)} className={`edit-${editContact ? "on" : "off"}`} placeholder="DDD + Número" value={contact} />
                            <div className="editButton">
                                {editContact ? (
                                    <>
                                        <img onClick={() => setEditContact(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditContact} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditContact(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>Rua</span>
                            <input readOnly={!editStreet} onChange={(e) => setStreet(e.target.value)} className={`edit-${editStreet ? "on" : "off"}`} placeholder="Rua, Av..." value={street} />
                            <div className="editButton">
                                {editStreet ? (
                                    <>
                                        <img onClick={() => setEditStreet(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditStreet(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>Número</span>
                            <input readOnly={!editStreetNumber} onChange={(e) => setStreetNumber(e.target.value)} className={`edit-${editStreetNumber ? "on" : "off"}`} placeholder="Ex: 1029" value={streetNumber} />
                            <div className="editButton">
                                {editStreetNumber ? (
                                    <>
                                        <img onClick={() => setEditStreetNumber(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditStreetNumber(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>Bairro</span>
                            <input readOnly={!editNeighborhood} onChange={(e) => setNeighborhood(e.target.value)} className={`edit-${editNeighborhood ? "on" : "off"}`} placeholder="Ex: Nova Iguaçul" value={neighborhood} />
                            <div className="editButton">
                                {editNeighborhood ? (
                                    <>
                                        <img onClick={() => setEditNeighborhood(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditNeighborhood(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>Cidade</span>
                            <input readOnly={!editCity} onChange={(e) => setCity(e.target.value)} className={`edit-${editCity ? "on" : "off"}`} placeholder="Ex: São José do Rio Preto" value={city} />
                            <div className="editButton">
                                {editCity ? (
                                    <>
                                        <img onClick={() => setEditCity(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditCity(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>Estado</span>
                            <input readOnly={!editState} onChange={(e) => setState(e.target.value)} className={`edit-${editState ? "on" : "off"}`} placeholder="Ex: São Paulo" value={state} />
                            <div className="editButton">
                                {editState ? (
                                    <>
                                        <img onClick={() => setEditState(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditState(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>País</span>
                            <input readOnly={!editCountry} onChange={(e) => setCountry(e.target.value)} className={`edit-${editCountry ? "on" : "off"}`} placeholder="Ex: Brasil" value={country} />
                            <div className="editButton">
                                {editCountry ? (
                                    <>
                                        <img onClick={() => setEditCountry(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditCountry(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>

                        <S.InfoBox>
                            <span>CEP</span>
                            <input readOnly={!editZipcode} onChange={(e) => setZipCode(e.target.value)} className={`edit-${editZipcode ? "on" : "off"}`} placeholder="Ex: 84021-291" value={zipcode} />
                            <div className="editButton">
                                {editZipcode ? (
                                    <>
                                        <img onClick={() => setEditZipcode(false)} src="cancel-icon.png" />
                                        <img onClick={handleEditAddress} src="save-icon.png" />
                                    </>
                                ) : (
                                    <>
                                        <img onClick={() => setEditZipcode(true)} src="edit-icon.png" />
                                    </>
                                )}
                            </div>
                        </S.InfoBox>
                    </S.ProfileInfo>
                </S.ProfileContent>
            </S.ProfileContainer>
        </>
    )
}