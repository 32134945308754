import React, { useState, useEffect, useContext } from "react";
import * as S from "./ContractsStyle";
import ContractPage from "./ContractPage/ContractPage";
import { AuthContext } from "../../context/AuthContext";
import { helpers } from "../../Helpers/helpers";

export default function Contracts() {
    const { modeloDeContratos } = useContext(AuthContext)

    const [pageContractShow, setPageContractShow] = useState(null);

    const onClose = () => {
        setPageContractShow(null);
    }

    return (
        <>
            {pageContractShow && (
                <ContractPage onClose={onClose} contract={pageContractShow} />
            )}

            <S.ContratosContainer>
                <S.Title>VEJA NOSSOS PRODUTOS</S.Title>


                <S.ProductsContainerArea style={{ marginTop: "100px" }}>
                    <h2>Produtos mais vendidos 🔥</h2>

                    <S.ProductsList>
                        {modeloDeContratos && modeloDeContratos.map((model, index) => (
                            <>
                                <S.Card>
                                    <p className="title">{model.title} 📄</p>

                                    <div className="productImage">
                                        <img src="introducer.jpeg.webp" />
                                    </div>

                                    <ul className="infoContract">
                                        <li className="info">Lucro de {model.gain*100}%</li>
                                        <li className="info">Saque liberado após {model.firstWithdraw} dias</li>
                                        <li className="info">Duração de {model.duration} meses</li>
                                    </ul>

                                    <div className="valorUnitario">
                                        <span className="valor">R${helpers.formatToBrazilianCurrency(model.value || 0)}</span>
                                    </div>

                                    <button className="selecionarBtn" onClick={() => setPageContractShow({
                                        uniValue: model.value || 0,
                                        daysToFirstWithdraw: model.firstWithdraw,
                                        duration: model.duration,
                                        finalIncome: model.gain*100,
                                        productName: model.title,
                                        daysToFirstWithdraw: model.firstWithdraw
                                    })}>Comprar</button>
                                </S.Card>
                            </>
                        ))}
                    </S.ProductsList>
                </S.ProductsContainerArea>
            </S.ContratosContainer>
        </>
    )
}