import React, { useContext, useState } from "react";
import * as S from "./EsqueceuSenhaStyle";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { helpers } from "../../Helpers/helpers";
import { useLoad } from "../../context/LoadContext";

const EsqueceuSenha = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const { startLoading, stopLoadingDelay } = useLoad();

    const handleSendLink = async () => {
        console.log("envinado link")
        if (email.trim() != "") {
            startLoading();
            var response = await helpers.enviarLinkEsqueceuSenha(email);

            if (response) {
                setMessage("Se o email estiver correto, um link de redefinição será enviado ao seu email em breve.")
                setError(false);
            } else {
                setMessage("Erro ao enviar link para redefinição de senha.")
                setError(true);
            }
            stopLoadingDelay();
        }
    }

    return (
        <S.LoginContent>
            <S.BackBlack />
            <S.LoginBox>
                <S.Logo src='imagemAu.png' alt="Logo" />

                {message.trim() === "" ? (
                    <>
                        <S.Title>Informe Seu Email de Cadastro.</S.Title>
                        <S.LoginForm>
                            <S.Input
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <S.SubmitButton onClick={handleSendLink}>Prosseguir</S.SubmitButton>
                        </S.LoginForm>
                    </>
                ) : (
                    <>
                        <S.Mensagem style={{ color: error ? "red" : "rgba(100, 255, 0, 1)" }}>{message}</S.Mensagem>
                        <S.SubmitButton onClick={() => navigate("/")}>Voltar</S.SubmitButton>
                    </>
                )}
            </S.LoginBox>
        </S.LoginContent>
    );
};

export default EsqueceuSenha;
