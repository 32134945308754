import React, { useContext, useEffect, useState } from "react";
import * as S from "./NoticiasStyle"
import { AuthContext } from '../../context/AuthContext';
import { helpers } from "../../Helpers/helpers";

export default function Noticias({ setA, A }) {
    const { noticias } = useContext(AuthContext);
    const [news, setNews] = useState([]);
    const [activeNews, setActiveNews] = useState(0);

    useEffect(() => {
        if (noticias) {
            setNews(noticias);
        }
    }, [noticias]);

    const handlePrev = () => {
        if (activeNews > 0) {
            setActiveNews(activeNews - 1);
        }
    }

    const handleNext = () => {
        if (activeNews < news.length - 1) {
            setActiveNews(activeNews + 1);
            console.log(noticias[activeNews]);
        }
    }

    return (
        <S.NoticiasContainer>
            <Noticia
                noticia={news[activeNews] || null}
                setA={setA}
                A={A}
                handlePrev={handlePrev}
                handleNext={handleNext}
            />
        </S.NoticiasContainer>
    )
}

function Noticia({ noticia, setA, A, handlePrev, handleNext }) {

    const handleOpen = () => {
        if (noticia.urlDestin && noticia.urlDestin.trim() != "") {
            window.open(noticia.urlDestin, "_blank");
        }
    }

    return (
        <S.Noticia>
            <S.NoticiaHeader>
                <span A={A}>Notícias</span>
                <div className="img-box">
                    <img alt="turn on" src="turnOn-icon.png" onClick={() => setA(!A)} />
                </div>
            </S.NoticiaHeader>
            <S.NoticiaDate A={A}>
                {noticia && helpers.formatDateToBrazilianFormat(noticia.dateCreated)}
            </S.NoticiaDate>

            <S.NoticiaTitle A={A}>
                {noticia && noticia.title}
            </S.NoticiaTitle>

            <S.NoticiaText A={A}>
                {noticia && noticia.text}
            </S.NoticiaText>

            <S.NoticiaImage A={A}>
                {noticia && noticia.urlMidea && (
                    <img onClick={handleOpen} alt="imagem da notícia" src={noticia.urlMidea || ""} />
                )}
            </S.NoticiaImage>

            <S.NoticiaButtons>
                <button onClick={handlePrev}>Anterior</button>
                <button onClick={handleNext}>Próxima</button>
            </S.NoticiaButtons>
        </S.Noticia>
    )
}