import React, { useContext, useEffect, useState } from "react";
import * as S from "./IndicacaoStyle";
import IndicationsTable from "../Tabelas/IndicationsTable";
import { AuthContext } from "../../context/AuthContext";

export default function Indicacao() {
    const { indicationPercent, clientData } = useContext(AuthContext);
    const [porcentagemDeIndicacao, setPorcentagemDeIndicacao] = useState(null);

    useEffect(() => {
        if (indicationPercent) {
            setPorcentagemDeIndicacao(indicationPercent);
        }
    }, [indicationPercent])

    const copyToClipboard = () => {
        const text = `http://localhost:3000/cadastroindicacao?id=${clientData.email}`
        console.log('text', text)
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <>
            <S.IndicationContainer>
                <S.Title>
                    SUAS INDICAÇÕES
                    <img alt="exit icon" src="indication-icon.png" />
                </S.Title>

                <S.Propaganda>
                    {indicationPercent ? (
                        <>
                            <p className="mensagem">INDIQUE E GANHE<span className="bold"> {indicationPercent}%</span> DE COMISSÃO</p>
                            <span className="yourLink">Obtenha seu link de indicação, <span className="bold" onClick={copyToClipboard}>CLIQUE AQUI.</span></span>
                        </>
                    ) : (
                        <>
                            <p>INDICAÇÃO DESATIVADA</p>
                        </>
                    )}

                </S.Propaganda>
                <S.IndicacaoTableContainer>
                    <IndicationsTable />
                </S.IndicacaoTableContainer>
            </S.IndicationContainer>
        </>
    )
}