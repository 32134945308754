import React, { useEffect, useState } from "react";
import * as S from "./AjudaStyle";
import { helpers } from "../../Helpers/helpers";
import CustomVideoPlayer from "./VideoPlayer/CustomVideoPlayer";

export default function Ajuda() {

    const [itemSelected, setItemSelected] = useState(helpers.optionsHelp[0].url)

    return (
        <>
            <S.AjudaContainer>
                <S.Title>Página de Ajuda 🛟</S.Title>

                <S.AjudaArea>
                    <S.SelectionArea>
                        <select onChange={(e) => setItemSelected(e.target.value)} className="selectItem">
                            {helpers.optionsHelp.map((op, key) => (
                                <>
                                    <option value={op.url} key={key}>{op.name}</option>
                                </>
                            ))}
                        </select>
                    </S.SelectionArea>

                    {itemSelected && (
                        <>
                            <S.VideoPart>
                                <CustomVideoPlayer url={itemSelected} />
                            </S.VideoPart>
                        </>
                    )}

                </S.AjudaArea>
            </S.AjudaContainer>
        </>
    )
}