import React, { useState, useEffect } from "react";
import * as S from "./ResetPasswordPageStyle";
import { helpers } from "../../Helpers/helpers";

export default function ResetPasswordPage() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState(""); // Inicialize como string

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token'); 
        setToken(tokenParam); 
    }, []);

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            alert("As senhas não coincidem!");
            return;
        }

        await helpers.confirmarMudarSenha(newPassword, token);
    };

    return (
        <>
            <S.Container>
                <S.Box>
                    <span className="texto">Digite sua nova senha</span>
                    <input
                        type="password"
                        placeholder="Digite aqui"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <span className="texto">Confirme sua nova senha</span>
                    <input
                        type="password"
                        placeholder="Digite aqui"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button onClick={handleSubmit}>CONFIRMAR ALTERAÇÃO</button>
                </S.Box>
            </S.Container>
        </>
    );
}